<script setup lang="ts">
import { useCompanyStore } from '@sub/stores/company';
import { storeToRefs } from 'pinia';
import { CategoriesCompanyEnum } from '@types';
import { until } from '@vueuse/core';

const store = useCompanyStore();
const { company } = storeToRefs(store);

const landingUrl = computed(() => {
  const isRu = company.value.meta?.phoneIso === 'RU';
  const domain = `https://cue-to.${isRu ? 'ru' : 'com'}`;
  const path = (company.value.meta?.category === CategoriesCompanyEnum.photoStudio && isRu) ? '/industry/photo-studios' : '';
  const query = `?utm_source=label&utm_medium=organic&utm_campaign=${company.value.idAlias || 'all'}`;
  return domain + path + query;
});

useAsyncData(() => until(company).toMatch(v => !!v.id, { timeout: 10000 }));
</script>

<template lang="pug">
NuxtLink.shield.flex-center-baseline.row-6.typ-14-300.m-t-auto(:to="landingUrl" target="_blank")
  | {{ $t('block.logo-shield.base.caption') }}
  span.typ-20-400 Cue
</template>

<style scoped lang="scss">
.shield {
  color: #ABB1B7;
  text-decoration: none;
}
</style>