export enum CategoriesCompanyEnum {
  photoStudio = 'photoStudio',// Фотостудия
  spaceForEvents = 'spaceForEvents', //Пространство для мероприятий
  meetingSpaces = 'meetingSpaces', //Переговорные пространства
  conferenceRooms = 'conferenceRooms', //Залы для конференций
  coworking = 'coworking', //Коворкинг
  recordingStudio = 'recordingStudio', //Студия звукозаписи
  fitnessRoom = 'fitnessRoom', //Фитнес зал
  sportsGrounds = 'sportsGrounds', //Спортивные площадки
  museumsAndGalleries = 'museumsAndGalleries', //Музеи и Галереи
  bathsAndSaunas = 'bathsAndSaunas', //Бани и сауны
  tennisCourt = 'tennisCourt', //Теннисный корт
  footballField = 'footballField', //Футбольное поле
  massageRoom = 'massageRoom', //Массажный кабинет
  climbingWall = 'climbingWall', //Скалодром
  questRoom = 'questRoom', //Квеструм
  karting = 'karting', //Картинг
  wakesurfOrWakeboard = 'wakesurfOrWakeboard', //Вейксерф/Вейкборд
  childrenRoomsOrPlaygrounds = 'childrenRoomsOrPlaygrounds', //Детские комнаты/Детские площадки
  sleepingRooms = 'sleepingRooms', //Комнаты для сна
  paintball = 'paintball', //Пейнтбол
  laserTag = 'laserTag', //Лазертаг
  consultations = 'consultations', //Консультации
  sapsurfing = "sapsurfing",
  psychologistOffice = "psychologistOffice",
  education = 'education', //Образование
  medicalServices = 'medicalServices', //Медицинские услуги
  beautyServices = 'beautyServices', //Бьюти услуги
  cosmetology = 'cosmetology', //Косметология
  trainings = 'trainings', //Тренинги
  spiritualPractices = 'spiritualPractices', //Духовные практики
  animalCare = 'animalCare', //Уход за животными
  trampolineCenter = 'trampolineCenter', //Батутный центр
  extremeSports = 'extremeSports', //Экстримальный спорт
  personalTraining = 'personalTraining', //Персональные тренировки
  photo = 'photo', //Фотография
  serviceCenter = 'serviceCenter', //Сервисный центр
  carService = 'carService', //Автосервис
  tireService = 'tireService', //Шиномонтаж
  carWash = 'carWash', //Автомойка
  atelier = 'atelier', //Ателье
  podcastStudio = 'podcastStudio', //Подкаст студия
  // other = 'other', //Другое
}
